type ENV = "dev" | "prod" | "demo";

export const currentEnv: string = "prod";

const env: string = currentEnv;

export const Env = env;

const API_DEV_URL = "https://easy-market-api.withvolkeno.com";
const API_DEMO_URL = "https://easy-market-api.withvolkeno.com";
const API_PROD_URL = "https://api.easymarket.sn";

const VENDEUR_APIKEY_DEMO =
  "4d100bfa052fb8aa87c3a7e38f5bc0fc3c2ef031ca62a09746ae1f3dc368ceff";
const VENDEUR_APIKEY_PROD =
  "4d100bfa052fb8aa87c3a7e38f5bc0fc3c2ef031ca62a09746ae1f3dc368ceff";

export const APP_URL_DEV = "https://easy-market.volkeno-engineering.click/";
export const APP_URL_DEMO = "https://easy-market.volkeno-engineering.click/";
export const APP_URL_PROD = "https://gestu.easymarket.sn/";

function processApiUrl() {
  if (env === "prod") return API_PROD_URL;
  if (env === "demo") return API_DEMO_URL;
  return API_DEV_URL;
}

function processAppUrl() {
  if (env === "prod") return APP_URL_PROD;
  if (env === "demo") return APP_URL_DEMO;
  return APP_URL_DEV;
}

function processApikey() {
  if (env === "prod") return VENDEUR_APIKEY_PROD;
  if (env === "demo") return VENDEUR_APIKEY_DEMO;
  return VENDEUR_APIKEY_DEMO;
}

export const ApiBaseUrl = processApiUrl();
export const AppBaseUrl = processAppUrl();

export const VendeurApikey = processApikey();

export const GoogleApikey = "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ";

export const IntechApikey = "";

export const GoogleClientId =
  "701177325323-33rje48k1alj39qmb9vspat9urmmrojn.apps.googleusercontent.com";
