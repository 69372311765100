import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import founder1 from "../../../assets/images/appImages/founder_1.png";
import founder2 from "../../../assets/images/appImages/founder_2.png";
import {
  AiOutlineGoogle,
  AiOutlineTwitter,
  AiFillFacebook,
  AiFillLinkedin,
} from "react-icons/ai";
import { TeamData } from "./data";
import { Link } from "react-router-dom";
import FooterPage from "../../auth/FooterPage";

const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

const TeamCard = ({
  photo,
  name,
  role,
  twitter,
  facebook,
  linkedin,
}: {
  photo?: string;
  name: string;
  role: string;
  twitter?: string;
  facebook?: string;
  linkedin?: string;
}) => {
  return (
    <div className="team-card">
      <span className="photo-wrapper">
        <img src={photo} alt="photo" />
      </span>
      <span className="name">{name}</span>
      <span className="role">{role}</span>
      <div className="social-networks d-flex align-items-center justify-content-center gap-2">
        <Link target="_blank" to={`#`}>
          <AiOutlineGoogle />
        </Link>
        <Link target="_blank" to={`${twitter}`}>
          <AiOutlineTwitter />
        </Link>
        <Link target="_blank" to={`${facebook}`}>
          <AiFillFacebook />
        </Link>
        <Link target="_blank" to={`${linkedin}`}>
          <AiFillLinkedin />
        </Link>
      </div>
    </div>
  );
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-5 pb-5">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="illustration-container">
                  <img src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">GËSTU</h3>
                <div className="custom-content">
                  Nous sommes une jeunesse soucieuse de retrouver ses origines
                  et s'imerger dans sa culture tout en respectant sa religion.{" "}
                  <br />
                  Dans un monde où l'apprentissage traditionnel est devenu un
                  frein au développement de nos enfants, nous sentons le besoin
                  de prendre les choses en main.
                </div>
              </div>
              <div className="col-md-12 pt-3">
                <div className="custom-content">
                  Notre cerveau est fait pour apprendre, se développer,
                  s'enrichir et se fortifier. <br />
                  L'enseignement par la punition et par le concours de notation
                  nous formate plus qu'il ne nous apprend. En ne se focalisent
                  que sur les notes, nous mettons notre cerveau dans une boite
                  où il se sent à l'étroit mais s'adapte pour ne pas sortir des
                  rangs. On ne tient pas compte sa capacité d'apprentissage
                  autonome et ludique ni ses mécanismes fondamentaux qui le
                  régissent. <br />
                  <br />
                  GËSTU veut révolutionner l'apprentissage, du moins celui de la
                  culture et de l'islam. Nous pensons très sincèrement que ces
                  deux axes sont le pilier de toute éducation et participe très
                  fortement à l'acquisition des valeurs et des principes qui
                  nous accompagne toute notre vie.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="plateform-founders">
          <div className="container">
            <CustomBeautyTitle title="Qui sommes nous?" />
            <h3 className="custom-title">Les fondateurs</h3>
            <div className="row pt-4">
              <div className="col-md-6">
                <div className="founder-card">
                  <span className="photo-wrapper">
                    <img src={founder1} alt="founder" />
                  </span>
                  <span className="name">Adja Amina MBOW</span>
                  <span className="role">Co-fondatrice de GËSTU</span>
                  <span className="description">
                    Ingénieur construction et mise en service électrique et
                    Artiste peintre et calligraphe à mes heures perdues.
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="founder-card">
                  <span className="photo-wrapper">
                    <img src={founder2} alt="founder" />
                  </span>
                  <span className="name">Cheick Fall GUEYE</span>
                  <span className="role">Co-fondateur de GËSTU</span>
                  <span className="description">
                    Ingénieur pédagogique et numérique et Écrivain à mes heures
                    perdues.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="our-team">
                    <div className="container">
                        <CustomBeautyTitle 
                            title="Team"
                        />
                        <h3 className="custom-title">Notre équipe</h3>
                        <div className="row pt-4">
                        {!!TeamData &&
                           TeamData.map((member, key) => {
                            return(
                                <div className="col-md-3 card-wrapper">
                                    <TeamCard 
                                        photo={member.photo}
                                        name={member.name}
                                        role={member.role}
                                        twitter={member.twitter}
                                        facebook={member.facebook}
                                        linkedin={member.linkedin}
                                    />
                                </div>
                            )
                           })
                        }
                        </div>
                    </div>
                </div> */}
      </div>
      {/* <FooterPage /> */}
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
